import React, { useState } from "react";
import userHelper from "../../helper/user";
import { useHistory } from "react-router-dom";
import GlobalButton, { BUTTON_TYPES } from "../globalButton/GlobalButton";

export default function ResendEmail(props) {
  const email = props.email;
  const history = useHistory();
  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [formChange, setFormChange] = useState(false);

  const handleInputChangeHref = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFormChange(true);

    try {
      props.setAlertStatus(false);
      const response = await userHelper.reSendEmail(email);
      props.setAlertMessage({
        level: "success",
        label: "Email inviata",
        message: "Email di conferma è stata inviata",
      });
      setLoading(false);
    } catch (error) {
      console.error(error.response);
      setResponse(error.response.data);
      setAlert("danger");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!!loading ? (
        <div className="spinner-border" role="status" />
      ) : (
        <>
          <>
            <div className="d-grid">
              <p>
                Controlla la tua casella di posta <strong>{email}</strong> e conferma la tua email
              </p>
            </div>
          </>
          {!formChange ? (
            <>
              <p>
                Se vuoi ricevere una nuova email{" "}
                <a href="#" role="button" onClick={handleInputChangeHref} className="text-decoration-none">
                  Clicca qui
                </a>
              </p>
              <GlobalButton buttonType={BUTTON_TYPES.a}>Vai al login</GlobalButton>
            </>
          ) : (
            <GlobalButton buttonType={BUTTON_TYPES.a}>Vai al login</GlobalButton>
          )}
        </>
      )}
    </>
  );
}
