import Wall from "../Wall";
import LoginForm from "../LoginForm/index";

function Login() {
  return (
    <Wall>
      <LoginForm />
    </Wall>
  );
}

export default Login;
