import React from "react";

export const BUTTON_TYPES = { a: "a" };

const GlobalButton = ({ children, buttonType, ...otherProp }) => {
  switch (buttonType) {
    case BUTTON_TYPES.a:
      return (
        <div className="d-grid pt-5">
          <a {...otherProp} href="/redirect" className="btn btn-primary btn-login text-uppercase mb-2">
            {children}
          </a>
        </div>
      );
    default:
      return (
        <div className="d-grid">
          <button
            {...otherProp}
            type="submit"
            form="loginForm"
            id="btn-login"
            className="btn btn-primary btn-login text-uppercase mb-2">
            {children}
          </button>
        </div>
      );
  }
};

export default GlobalButton;
