import React, { useState } from "react";

import Alert from "../Alert/";

import logo from "../../statics/images/logo_ras_bandiera.svg";
import "./style.css";

function Wall(props) {
  const [message, setMessage] = useState(undefined);
  const [alertStatus, setAlertStatus] = useState(false);

  const setAlertMessage = (message) => {
    setMessage(message);
    setAlertStatus(true);
  };

  return (
    <div id="wall">
      <div className="container-fluid ps-md-0">
        <div className="row g-0">
          <div className="d-none d-md-flex col-md-4 col-lg-8 bg-image"></div>
          <div id="login-form" className="col-md-8 col-lg-4">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-10 mx-auto">
                    <div className="logo-title-container">
                      <img
                        id="ras-logo"
                        className="img-responsive pull-left flip logo hidden-xs animated fadeIn"
                        src={logo}
                        alt="Logo RAS"
                      />
                      <div className="copy animated fadeIn">
                        <h1>Benvenuto nel Portale ARES</h1>
                        <p>Portale di gestione ARES</p>
                      </div>
                    </div>
                    <Alert message={message} alertStatus={alertStatus} setAlertStatus={setAlertStatus} />
                    {React.cloneElement(props.children, { message, setAlertMessage, setAlertStatus })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wall;
