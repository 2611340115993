import React from "react";
import RegistrationFormWithLibrary from "../Registration/RegistrationFormWithLibrary";
import Wall from "../Wall";

function Registration() {
  return (
    <Wall>
      <RegistrationFormWithLibrary />
    </Wall>
  );
}
export default Registration;
