import axios from 'axios';
import Cookies from 'js-cookie'


const login = async(email, password) =>  {

  axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;

  const url = `/api/v1/auth`;
  const datetime = new Date().toLocaleString();

  const response = await axios.post(url, {
      email: email,
      password: password,
      datetime
    });

  const { data } = response;
  save(data);

  console.log(data, response);

  return response;
}

const logout = () => {
  remove();
}

const save = (value) => {
  const name = process.env.REACT_APP_STORAGE_NAME;
  const domain = process.env.REACT_APP_COOKIE_DOMAIN;
  const json = JSON.stringify(value);
  Cookies.set(
    name,
    json,
    {
      domain: domain,
      secure: false,
      sameSite: 'lax',
      expires: 365,
    }
  );
}

const get = () => {
  const name = process.env.REACT_APP_STORAGE_NAME;

  const cookie = Cookies.get(name);
  return JSON.parse(cookie);
}

const remove = () => {
  const name = process.env.REACT_APP_STORAGE_NAME;
  // Cookies.remove(name);
}

const authHelper =  {
  login,
  logout,

  save,
  get,
  remove,
}

export default authHelper;