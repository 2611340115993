import React, { useState, useEffect } from "react";

function Alert(props) {
  const [level, setLevel] = useState("warning");
  const [label, setLabel] = useState("test");
  const [text, setText] = useState("");

  const { message, alertStatus, setAlertStatus } = props;

  useEffect(() => {
    if (!!message) {
      const { level, label, text } = message;
      setLevel(level);
      setLabel(label);
      setText(text);
    }
  }, [message]);

  return (
    <>
      {!!alertStatus ? (
        <div className={`alert alert-${level} alert-dismissible fade show`} role="alert">
          <strong>{label}</strong>
          <br />
          {!!text ? text : ""}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setAlertStatus(false)}></button>
        </div>
      ) : undefined}
    </>
  );
}

export default Alert;
