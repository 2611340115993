import React, { useEffect, useState } from "react";
import userHelper from "../../helper/user";
import GlobalButton, { BUTTON_TYPES } from "../globalButton/GlobalButton";
import Wall from "../Wall";

function ConfirmRegistration() {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);

  const { searchParams } = new URL(window.location.href);

  const handleInputChange = async () => {
    try {
      setLoading(true);
      const status = await userHelper.confirmEmail(searchParams.get("email"), searchParams.get("token"));
      setResponse(status.data);
      setAlert("success");
      setAlertStatus(true);
    } catch (error) {
      console.error(error.response.data);
      setResponse(error.response.data);
      setAlert("danger");
      setAlertStatus(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleInputChange();
  }, []);

  return (
    <Wall>
      {!!loading ? (
        <div className="spinner-border" role="status" />
      ) : (
        <div className="pt-4">
          {!!alert && alertStatus && (
            <>
              {" "}
              <div className={`alert alert-${alert} alert-dismissible fade show`} role="alert">
                <strong>{response}</strong>
                <br></br>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlertStatus(false)}></button>
              </div>
            </>
          )}
          <GlobalButton buttonType={BUTTON_TYPES.a}>Vai alla login</GlobalButton>
        </div>
      )}
    </Wall>
  );
}

export default ConfirmRegistration;
