import React from "react";
import ChangePasswordForm from "../ChangePassword/ChangePasswordForm";
import Wall from "../Wall";

function ChangePassword() {
  return (
    <Wall>
      <ChangePasswordForm />
    </Wall>
  );
}

export default ChangePassword;
