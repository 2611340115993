import React from "react";
import { useState } from "react";
import userHelper from "../../helper/user";
import { useForm } from "react-hook-form";
import GlobalButton, { BUTTON_TYPES } from "../globalButton/GlobalButton";

function ForgotPasswordForm(props) {
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");

  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
  });

  const email_regex = new RegExp(process.env.REACT_APP_REGEX_EMAIL);

  const handleInputChange = async (data) => {
    setLoading(true);
    props.setAlertStatus(false);
    try {
      const status = await userHelper.forgotPassword(data.email);
      props.setAlertMessage({
        level: "success",
        label: "Email inviata",
        message: "Email di conferma è stata inviata",
      });
      setAlert("success");
    } catch (error) {
      console.error(error.response);
      setResponse(error.response.data);
      setAlert("danger");
      props.setAlertMessage({
        level: "danger",
        label: "Qualcosa è andata in errore",
        message: "Qualcosa è andata in errore",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!!loading ? (
        <div className="spinner-border" role="status" />
      ) : (
        <>
          {!!alert && alertStatus && (
            <>
              {" "}
              <div className={`alert alert-${alert} alert-dismissible fade show`} role="alert">
                <strong>{response}</strong>
                <br></br>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlertStatus(false)}></button>
              </div>
            </>
          )}
          {alert !== "success" && (
            <>
              <p className="login-heading mb-4">Ripristina password:</p>
              <form id="loginForm" onSubmit={handleSubmit(handleInputChange)}>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    {...register("email", {
                      required: true,
                      pattern: new RegExp(process.env.REACT_APP_REGEX_EMAIL), // mettere direttamente la regex
                    })}
                    className={`form-control ${!errors?.email ? " " : " is-invalid "}`}
                    id="email"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInput">Email</label>
                  {errors.email && <p className="ps-2 text-danger">Formato email non valido</p>}
                </div>
                <GlobalButton disabled={!isDirty || !isValid}> Ripristina Password </GlobalButton>
                <div className="pt-4">
                  Hai già un'account?{" "}
                  <a href="/login" className="non-account text-decoration-none">
                    Accedi
                  </a>
                </div>
              </form>
            </>
          )}
          {alert === "success" && (
            <>
              <div className="d-grid pt-2">
                <p>
                  Controlla la tua casella di posta <strong>{email}</strong> e cambia la tua password.
                </p>
              </div>

              <GlobalButton buttonType={BUTTON_TYPES.a}>Vai al login</GlobalButton>
            </>
          )}
        </>
      )}
    </>
  );
}
export default ForgotPasswordForm;
