import React from "react";
import ForgotPasswordForm from "../ForgotPassword/ForgotPasswordForm";
import Wall from "../Wall";

function ForgotPassword() {
  return (
    <Wall>
      <ForgotPasswordForm />
    </Wall>
  );
}
export default ForgotPassword;
